/* Address View, Vue Component */
<template>
  <div>
  <member-summary v-if="member && member.Id && !loading"
  :title="`Member - ${member.Reference} - ${member.FullName}`"
  :member="member"></member-summary>

  <v-card v-if="entity && entity.Id && !loading" id="address-view" class="mt-6 entity-view">
      <v-card-title>View Address</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'address-edit', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span>Edit</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'address-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Address</span>
        </v-btn>
        <v-btn
          color="secondary"
          class="me-3"
          @click="Back"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiChevronLeft }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Full Name</td>
        <td>{{ entity.FullName }}</td>
      </tr>

      <tr>
          <td>Salutation</td>
          <td>{{ entity.Salutation }}</td>
      </tr>

      <tr>
        <td>Line 1</td>
        <td>{{ entity.Address1 }}</td>
      </tr>

      <tr>
        <td>Line 2</td>
        <td>{{ entity.Address2 }}</td>
      </tr>

      <tr>
        <td>Line 3</td>
        <td>{{ entity.Address3 }}</td>
      </tr>

      <tr>
        <td>Line 4</td>
        <td>{{ entity.Address4 }}</td>
      </tr>

      <tr>
        <td>Postcode</td>
        <td>{{ entity.Postcode }}</td>
      </tr>

      <tr>
        <td>Telephone</td>
        <td>{{ entity.Telephone }}</td>
      </tr>

      <tr>
        <td>IsDeleted</td>
        <td><v-icon size="18" :color="booleanIcons(entity.IsDeleted).variant">{{ booleanIcons(entity.IsDeleted).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Delivery Point Id</td>
        <td>{{ entity.DeliveryPointId }}</td>
      </tr>

      <tr>
        <td>Member Reference</td>
        <td>{{ entity.MemberId_Reference }}</td>
      </tr>

		      <tr>
        <td>Member Email</td>
        <td>{{ entity.MemberId_Email }}</td>
      </tr>

      <tr>
        <td>Country Name</td>
        <td>{{ entity.CountryId_Name }}</td>
      </tr>

      <tr>
        <td>Created</td>
        <td>{{ Display.DateTime(entity.Created) }}</td>
      </tr>

      <tr>
        <td>Updated</td>
        <td>{{ Display.DateTime(entity.Updated) }}</td>
      </tr>

        </tbody>
      </v-simple-table>

  </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import MemberSummary from '../member/components/MemberSummary'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiChevronLeft
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  components: {
      MemberSummary
    },
    setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Address);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchAddress', {
          id: id
        })
        .then(response => {
          loading.value = false
          fetchMember(response.data.MemberId);
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'address-list'});
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);

    const member = computed(() => store.state.app.Member);

    const fetchMember = (id) => {
      store
        .dispatch('app/fetchMember', {
          id: id
        })
    }

      // fetch the user information when params change
      watch(() => entity.MemberId, (id) => fetchMember(id));


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    const Back = () => {
      router.go(-1);
    };

    return {
      member,
      Back,
      loading,
      entity,
      Display,
      booleanIcons,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose,
        mdiChevronLeft
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

